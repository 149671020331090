import logo from './logo.svg';
import './Landing.css';

function Landing() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            Translate your website to every language with 2 lines of code
        </p>
        <code className="code-block">{"<Internationalize>\n  <App />\n</Internationalize> "}</code>
          <a
          className="App-link"
          href="https://demo.internationalize.website"
          target="_blank"
          rel="noopener noreferrer"
        >
            Play with a live demo here
        </a>
        <br />
        <a
          className="App-link"
          href="https://buy.internationalize.website/l/internationalize"
          target="_blank"
          rel="noopener noreferrer"
        >
            Get started here
        </a>
        
      </header>
    </div>
  );
}

export default Landing;
